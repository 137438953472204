import React from "react";
import { Link } from "gatsby";

import "./postLink.scss";

const PostLink = ({ link, date, title, description, timeToRead }) => (
	<Link className="post-link" to={link}>
		<div className="post-link-title">
			<h3>{title}</h3>
		</div>
		<div className="post-link-details">
			<p>{description}</p>
		</div>
		<div className="post-link-meta">
			<small>{date} - {timeToRead} minute read</small>
		</div>
	</Link>
);

export default PostLink;
