import React from "react";
import { Link } from "gatsby";

import "./pageNav.scss";

const PageNav = ({ currentPage, numPages }) => {
	const isFirst = currentPage === 1;
	const isLast = currentPage === numPages;
	const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString();
	const nextPage = (currentPage + 1).toString();

	return (
		<div className="page-nav">
			{!isFirst && (
				<Link to={prevPage} rel="prev" className="page-nav-prev">
					← Previous Page
				</Link>
			)}
			{!isLast && (
				<Link to={nextPage} rel="next" className="page-nav-next">
					Next Page →
				</Link>
			)}
		</div>
	);
};

export default PageNav;
