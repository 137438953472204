import React from "react";

import PostLink from "./postLink";

const PostLinks = ({ posts }) => (
	<>
		{posts.map((post) => (
			<PostLink key={post.link} {...post} />
		))}
	</>
);

export default PostLinks;
