import React, { useMemo } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import PostLinks from "../components/postLinks";
import PageNav from "../components/pageNav";
import SEO from "../components/seo";

function transformBlogPageList(data) {
  return data.allMarkdownRemark.edges.map(({ node }) => ({
    link: node.fields.slug,
    date: node.frontmatter.date,
    title: node.frontmatter.title,
    description: node.frontmatter.description,
    timeToRead: node.timeToRead,
  }));
}

const BlogPage = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext;
  const postLinks = useMemo(() => transformBlogPageList(data), [data]);

  return (
    <Layout>
      <SEO title="Home" />
      <PostLinks posts={postLinks} />
      <PageNav currentPage={currentPage} numPages={numPages} />
    </Layout>
  );
};

export const blogPageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;

export default BlogPage;
